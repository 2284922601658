import React from "react"
import Logout from "../components/Logout"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LogoutPage = () => (
  <Layout>
    <SEO title="CCO Login" />
    <Logout />
  </Layout>
)

export default LogoutPage
