import React from "react"
import { navigate } from "gatsby"
import { logout, isBrowser } from "../../utils/Auth"

const Logout = () => {
  if (!isBrowser) return false

  logout()
  navigate("/")

  return false
}

export default Logout
